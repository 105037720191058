<template>
  <div>
    <b-modal
      id="modal-scrollable"
      :visible="shallShowEmailInfoModal"
      scrollable
      size="xl"
      :title="`${info.Recipients ? info.Recipients.join(', ') : null} -- ${info.Subject}`"
      ok-only
      @change="(val) => $emit('update:shall-show-email-info-modal', val)"
    >

      <!-- EVENTS -->
      <app-timeline>
        <app-timeline-item
          title="Processed"
          subtitle="We received the message and are sending it to the recipient’s mail server."
          variant="Processed"
        />
        <!--
            Transient,
            SubscriptionChanged,
            Delivered,
            Opened,
            LinkClicked,
            Bounced
        -->
        <template v-if="info.MessageEvents && info.MessageEvents.length">
          <app-timeline-item
            v-for="(event, index) in info.MessageEvents.filter(event => event.Type != 'SubscriptionChanged')"
            :key="index"
            :title="event.Type == 'LinkClicked' ? 'Clicked' : event.Type"
            :subtitle="getSubtitle(event.Details)"
            :time="event.ReceivedAt | postmarktime"
            :variant="event.Type"
          />
        </template>
      </app-timeline>

      <!-- BODY -->
      <div class="mt-4">
        <b-tabs>
          <b-tab
            active
            title="HTML"
          >
            <div v-html="info.HtmlBody" />
          </b-tab>
          <b-tab title="Plain Text">
            <div
              class="email-preview_text"
              v-html="info.TextBody"
            />
          </b-tab>
          <b-tab title="Raw Source">
            <div class="email-preview_snippet">
              <pre>
                <code
                    class="yaml hljs language-yaml"
                    v-html="rawSource"
                />
                </pre>
            </div>
          </b-tab>
        </b-tabs>
      </div>

    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment'
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
    components: {
        BTab,
        BTabs,
        BCardText,
        BCardCode,
        AppTimeline,
        AppTimelineItem,
    },
    model: {
        prop: 'shallShowEmailInfoModal',
        event: 'update:shall-show-email-info-modal',
    },
    props: {
        info: {
            type: Object,
            default: () => {},
        },
        shallShowEmailInfoModal: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        shallShowEmailInfoModal: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.rawSource = hljs.highlight(this.info.Body, {language: 'yaml'}).value
                } else {
                    this.rawSource = null
                }
            }
        }
    },
    data() {
        return {
            rawSource: null
        }
    },
    filters: {
        postmarktime: function(value) {
            const time = moment(value)
            return `${time.format('MMM DD')} - ${time.format('H:m A')}`
        }
    },
    methods: {
        getSubtitle(message) {
            if (message.Summary) return message.Summary
            if (message.DeliveryMessage) return message.DeliveryMessage

            return 'We received the message and are sending it to the recipient’s mail server.'
        },
        getVariant(event) {
            let variant = 'success'
            console.log({ event })
            switch (event.Type) {
                case 'SubscriptionChanged':
                    variant = 'light-success'
                    break

                default:
                    break
            }

            return variant
        },
    },
}
</script>

<style lang="scss">
.timeline-item {
    &.timeline-variant-Processed {
        .timeline-item-point {
            background-color: #c6e091;
        }
    }

    .Processed {
        background-color: #c6e091;
    }

    &.timeline-variant-Bounced {
        .timeline-item-point {
            background-color: #d14642;
        }
    }

    .Bounced {
        background-color: #d14642;
    }

    &.timeline-variant-Delivered {
        .timeline-item-point {
            background-color: #89bf1d;
        }
    }

    .Delivered {
        background-color: #89bf1d;
    }

    &.timeline-variant-Opened {
        .timeline-item-point {
            background-color: #5cc3cc;
        }
    }

    .Opened {
        background-color: #5cc3cc;
    }

    &.timeline-variant-LinkClicked {
        .timeline-item-point {
            background-color: #b47cd4;
        }
    }

    .LinkClicked {
        background-color: #b47cd4;
    }

    .Opened,
    .Bounced,
    .Processed,
    .Delivered,
    .LinkClicked,
    .SubscriptionChanged {
        padding: 5px 10px;
        color: #fff;
        border-radius: 20px;
    }
}

.email-preview_text {
    min-height: 600px;
    padding: 15px;
    color: #272727;
    font: normal 14px/1.5 iA Writer Mono,monospace,serif;
    white-space: pre-wrap;
    word-break: break-all;
}

.email-preview_snippet {
    position: relative;
    z-index: 3;
    margin: 15px;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #3b4049!important;

    pre {
        width: 100%;
        overflow: auto;
        display: block;
        box-sizing: border-box;
        padding: 25px;
        background-color: transparent;

         code {
            color: #fff;
            font-size: 14px;
            line-height: 1.6;
            background-color: transparent;
        }
    }
}

.yaml {
    .hljs-attr,
    .hljs-type {
        color: #8cd6a5;
    }

    .hljs-bullet,
    .hljs-literal,
    .hljs-number,
    .hljs-string {
        color: #fff;
    }

    .hljs-code,
    .hljs-comment,
    .hljs-formula {
        color: #a8afba;
        font-style: italic;
    }
}

.nav-tabs .nav-link {
    text-decoration: none!important;
}
</style>
