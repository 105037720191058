var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{staticStyle:{"width":"60rem!important"},attrs:{"id":"sidebar-task-handler","sidebar-class":"sidebar-lg","visible":_vm.isTaskHandlerSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-task-handler-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Add CRM ")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"addCRMRef"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.addCRM($event)},"reset":function($event){$event.preventDefault();_vm.crm = {}}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"crm-name"}},[_c('b-form-input',{attrs:{"id":"crm-name","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Name"},model:{value:(_vm.crm.name),callback:function ($$v) {_vm.$set(_vm.crm, "name", $$v)},expression:"crm.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"crm-email"}},[_c('b-form-input',{attrs:{"id":"crm-email","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Email"},model:{value:(_vm.crm.email),callback:function ($$v) {_vm.$set(_vm.crm, "email", $$v)},expression:"crm.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"jobTitle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Job Title","label-for":"crm-jobTitle"}},[_c('b-form-input',{attrs:{"id":"crm-jobTitle","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Job Title"},model:{value:(_vm.crm.jobTitle),callback:function ($$v) {_vm.$set(_vm.crm, "jobTitle", $$v)},expression:"crm.jobTitle"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"crm-phone"}},[_c('b-form-input',{attrs:{"id":"crm-phone","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Phone Number"},model:{value:(_vm.crm.phone),callback:function ($$v) {_vm.$set(_vm.crm, "phone", $$v)},expression:"crm.phone"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Gender","label-for":"crm-gender"}},[_c('v-select',{attrs:{"id":"crm-gender","label":"Gender","state":errors.length > 0 ? false : null,"placeholder":"Gender","options":['Male', 'Female', 'Other']},model:{value:(_vm.crm.gender),callback:function ($$v) {_vm.$set(_vm.crm, "gender", $$v)},expression:"crm.gender"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"birthday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Birth Date","label-for":"crm-birthday"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"crm-birthday","state":errors.length > 0 ? false : null,"placeholder":"Birth Date"},model:{value:(_vm.crm.birthday),callback:function ($$v) {_vm.$set(_vm.crm, "birthday", $$v)},expression:"crm.birthday"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"facebook"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Facebook","label-for":"crm-facebook"}},[_c('b-form-input',{attrs:{"id":"crm-facebook","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Facebook"},model:{value:(_vm.crm.facebook),callback:function ($$v) {_vm.$set(_vm.crm, "facebook", $$v)},expression:"crm.facebook"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"linkedin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Linkedin","label-for":"crm-linkedin"}},[_c('b-form-input',{attrs:{"id":"crm-linkedin","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Linkedin"},model:{value:(_vm.crm.linkedin),callback:function ($$v) {_vm.$set(_vm.crm, "linkedin", $$v)},expression:"crm.linkedin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"twitter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Twitter","label-for":"crm-twitter"}},[_c('b-form-input',{attrs:{"id":"crm-twitter","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Twitter"},model:{value:(_vm.crm.twitter),callback:function ($$v) {_vm.$set(_vm.crm, "twitter", $$v)},expression:"crm.twitter"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"instagram"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Instagram","label-for":"crm-instagram"}},[_c('b-form-input',{attrs:{"id":"crm-instagram","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Instagram"},model:{value:(_vm.crm.instagram),callback:function ($$v) {_vm.$set(_vm.crm, "instagram", $$v)},expression:"crm.instagram"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"youtube"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Youtube","label-for":"crm-youtube"}},[_c('b-form-input',{attrs:{"id":"crm-youtube","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Youtube"},model:{value:(_vm.crm.youtube),callback:function ($$v) {_vm.$set(_vm.crm, "youtube", $$v)},expression:"crm.youtube"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"tiktok"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tiktok","label-for":"crm-tiktok"}},[_c('b-form-input',{attrs:{"id":"crm-tiktok","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Tiktok"},model:{value:(_vm.crm.tiktok),callback:function ($$v) {_vm.$set(_vm.crm, "tiktok", $$v)},expression:"crm.tiktok"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"success","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?[_c('b-spinner',{staticClass:"mr-50",attrs:{"small":"","type":"grow"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Loading...")])]:[_vm._v(" Add CRM ")]],2),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary","disabled":_vm.isLoading}},[_vm._v(" Reset ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }