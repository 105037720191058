<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      style="width: 60rem!important;"
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            Add CRM
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          ref="addCRMRef"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="addCRM"
            @reset.prevent="crm = {}"
          >

            <b-row>

              <!-- Name -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    label="Name"
                    label-for="crm-name"
                  >
                    <b-form-input
                      id="crm-name"
                      v-model="crm.name"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Name"
                    />

                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Email -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group
                    label="Email"
                    label-for="crm-email"
                  >
                    <b-form-input
                      id="crm-email"
                      v-model="crm.email"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Email"
                    />

                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- JobTitle -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="jobTitle"
                >
                  <b-form-group
                    label="Job Title"
                    label-for="crm-jobTitle"
                  >
                    <b-form-input
                      id="crm-jobTitle"
                      v-model="crm.jobTitle"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Job Title"
                    />

                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Phone -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                >
                  <b-form-group
                    label="Phone Number"
                    label-for="crm-phone"
                  >
                    <b-form-input
                      id="crm-phone"
                      v-model="crm.phone"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Phone Number"
                    />

                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Gender -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="gender"
                >
                  <b-form-group
                    label="Gender"
                    label-for="crm-gender"
                  >
                    <v-select
                      id="crm-gender"
                      v-model="crm.gender"
                      label="Gender"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Gender"
                      :options="['Male', 'Female', 'Other']"
                    />

                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Date Of Birth -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="birthday"
                >
                  <b-form-group
                    label="Birth Date"
                    label-for="crm-birthday"
                  >
                    <flat-pickr
                      id="crm-birthday"
                      v-model="crm.birthday"
                      :state="errors.length > 0 ? false : null"
                      class="form-control"
                      placeholder="Birth Date"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Facebook -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="facebook"
                >
                  <b-form-group
                    label="Facebook"
                    label-for="crm-facebook"
                  >
                    <b-form-input
                      id="crm-facebook"
                      v-model="crm.facebook"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Facebook"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Linkedin -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="linkedin"
                >
                  <b-form-group
                    label="Linkedin"
                    label-for="crm-linkedin"
                  >
                    <b-form-input
                      id="crm-linkedin"
                      v-model="crm.linkedin"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Linkedin"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Twitter -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="twitter"
                >
                  <b-form-group
                    label="Twitter"
                    label-for="crm-twitter"
                  >
                    <b-form-input
                      id="crm-twitter"
                      v-model="crm.twitter"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Twitter"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Instagram -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="instagram"
                >
                  <b-form-group
                    label="Instagram"
                    label-for="crm-instagram"
                  >
                    <b-form-input
                      id="crm-instagram"
                      v-model="crm.instagram"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Instagram"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Youtube -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="youtube"
                >
                  <b-form-group
                    label="Youtube"
                    label-for="crm-youtube"
                  >
                    <b-form-input
                      id="crm-youtube"
                      v-model="crm.youtube"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Youtube"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Tiktok -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="tiktok"
                >
                  <b-form-group
                    label="Tiktok"
                    label-for="crm-tiktok"
                  >
                    <b-form-input
                      id="crm-tiktok"
                      v-model="crm.tiktok"
                      :state="errors.length > 0 ? false : null"
                      trim
                      placeholder="Tiktok"
                    />

                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ADD BUTTON -->
              <b-col md="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2"
                  type="submit"
                  :disabled="isLoading"
                >
                  <template v-if="isLoading">
                    <b-spinner
                      small
                      type="grow"
                      class="mr-50"
                    />
                    <span class="align-middle">Loading...</span>
                  </template>
                  <template v-else>
                    Add CRM
                  </template>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  :disabled="isLoading"
                >
                  Reset
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BRow, BCol, BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BSpinner,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: false,
    },
    task: {
      type: Object,
      required: false,
    },
    clearTaskData: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      crm: {
        name: null,
        email: null,
        phone: null,
        gender: null,
        jobTitle: null,
        birthday: null,
        facebook: null,
        linkedin: null,
        twitter: null,
        instagram: null,
        youtube: null,
        tiktok: null,
      },
      isLoading: false,
      required,
      email,
      url,
    }
  },
  methods: {
    ...mapActions('founders', ['addFounderAction']),
    addCRM() {
      this.$refs.addCRMRef.validate().then(success => {
        if (success) {
          this.isLoading = true

          this.addFounderAction({ ...this.crm }).then(() => {
            this.showToast('User data added successfully')
            this.crm = {
              name: null,
              email: null,
              phone: null,
              gender: null,
              jobTitle: null,
              birthday: null,
              facebook: null,
              linkedin: null,
              twitter: null,
              instagram: null,
              youtube: null,
              tiktok: null,
            }
            this.$emit('addFounder')
          }).catch(({ response }) => {
            this.showToast(response.data.message, 'danger')
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';

#sidebar-task-handler {
    width: 60rem!important;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
